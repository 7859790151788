<template>
  <v-col
    class="d-flex justify-end align-end"
    :class="clase"
  >
    <v-btn
      title="Limpiar filtro"
      color="orange"
      class="mr-3"
      icon
      @click="limpiar"
    >
      <v-icon>fas fa-broom</v-icon>
    </v-btn>
    <v-btn
      color="info"
      type="submit"
      tabindex="1"
      :disabled="disabled"
      :loading="loading"
    >
      <v-icon left>{{ icono }}</v-icon>
      {{  nombre  }}
    </v-btn>
  </v-col>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // permite agregar un class extra a la fila del boton, por defecto es margin top: mt-4
    clase: {
      type: String,
      default: 'mt-4'
    },
    // nombre del boton e icono
    nombre: {
      type: String,
      default: 'Buscar'
    },
    icono: {
      type: String,
      default: 'fas fa-search'
    }
  },
  methods: {
    limpiar () {
      this.$emit('clear')
    }
  }
}
</script>